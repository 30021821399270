<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 p-0">
      <div class="row m-0 justify-content-center">
        <div class="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 col-xxl-6 p-0 my-4 card_item">
          <div class="row mx-0 h-100 justify-content-center">
            <div class="col-12 p-0">
              <ContentWithBackground>
                <div class="row m-0 justify-content-center py-3">
                  <div class="col-11 text-center mb-4">
                    <div class="heading">
                      Jazakallah Khair for your generous Donation, may Almighty Allah reward you abundantly.
                    </div>
                  </div>
                  <div class="col-11 mb-3">
                    May Almighty Allah grant you and your family blessing in your health and wealth and protect you from all harm and may He make this donation a means of salvation in the hereafter. We hope you will continue to support our needy brothers and sisters and may He always use you to alleviate the suffering of the Ummah - Aameen.
                  </div>
                  <div class="col-11 mb-3">
                    Al-Imdaad Foundation has been working in more than 60 countries by responding to disaster zones, providing humanitarian aid along with long term sustainable development programmes such as healthcare, nutrition, shelter, construction and education.  It is through your humble Du'aas and generosity in supporting our projects across the globe that enables us to reach out to even more communities, families, orphans and widows.
                  </div>
                  <div class="col-11 mb-3">
                    If you have any questions about your donation or would like to learn more about our work, please email <span v-if="isUK">info@alimdaad.co.uk</span><span v-else>queries@alimdaad.com</span> or call our freephone number <span v-if="isUK">01254 698771</span><span v-else>0861 786 243</span>.
                  </div>
                  <div class="col-11 mb-3">
                    Jazakallah Khair
                  </div>
                </div>
              </ContentWithBackground>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentWithBackground from '@/components/styling/ContentWithBackground.vue'

export default {
  components: {
    ContentWithBackground
  },
  name: 'PaymentCompleteText',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}

</style>
